import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Grid, Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import InputAutoComplete from '../../../components/Inputs/InputAutoComplete';
import { createTFGInstructorAllocation, getTFGInstructorInfo, getTFGInstructors, updateTFGInstructorAllocation, uploadInstructorsCSV } from '../../../services/TFG/TFGService';
import { useNotification } from '../../../Utils/hooks';
import { copyToClipboard } from '../../../Utils/utils';
import TFGConfigResultDialog from './TFGConfigResultDialog/TFGConfigResultDialog';
import TFGAcademicYearSelector from '../TFGAcademicYearSelector/TFGAcademicYearSelector';
import InstructorAllocationDialog from './TFGInstructorAllocationDialog/InstructorAllocationDialog';

const CSVKeys = ['academicYearId', 'externalId', 'limitRequestsTFG'];

const TFGConfig = () => {
  const [managers, setManagers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [coord, setCoord] = useState(null);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [showAllocationDialog, setShowAllocationDialog] = useState(false);
  const [results, setResults] = useState({ success: [], failed: [] });
  const notification = useNotification();
  const fileUploadRef = useRef(null);

  const retrieveManagers = useCallback(async () => {
    try {
      setApplicationLoading(true);
      const result = await getTFGInstructors().then(
        res => res.map(item => ({ value: item.externalId, label: item.name, max: item.limitRequestsTFG }))
      );
      if (!result) throw new Error();
      const ordered = result.sort((first, second) => first.label.localeCompare(second.label));
      setManagers(ordered);
    } catch {
      notification('error', 'Ha habido un error al obtener los coordinadores');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    retrieveManagers();
  }, [retrieveManagers]);

  useEffect(() => {
    async function retrieveManagerInfo(id) {
      try {
        setApplicationLoading(true);
        const result = await getTFGInstructorInfo(id);
        if (!result) throw new Error();
        setCoord(result);
      } catch {
        notification('error', 'Ha habido un error al obtener la información del coordinador');
      } finally {
        setApplicationLoading(false);
      }
    }

    selected && retrieveManagerInfo(selected);
  }, [selected, notification]);

  useEffect(() => {
    if (!selected) setCoord(null);
  }, [selected]);

  const instructorYearAllocation = useMemo(() => {
    if (coord && selectedTerm) {
      const allocation = coord.yearlyAllocations.find(item => item.academicYearId === selectedTerm);
      return allocation;
    }

    return null;
  }, [coord, selectedTerm])

  const onUploadClick = event => {
    event.preventDefault();

    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  }

  const onCopyClick = () => {
    copyToClipboard(CSVKeys.join(','));
    notification('info', 'Las cabeceras se han copiado al portapapeles');
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) {
        try {
          setApplicationLoading(true);
          const result = await uploadInstructorsCSV(file);
          if (!result) throw new Error();
          setResults(result);
          setShowResultDialog(true);
          retrieveManagers();
        } catch {
          notification('error', 'Ha habido un error al subir el CSV');
        } finally {
          setApplicationLoading(false);
        }
      }
    }
  }

  const onHideResultsDialog = () => {
    setShowResultDialog(false);
    setTimeout(() => setResults({ success: [], failed: [] }), 250);
  }

  const onHideAllocationDialog = () => {
    setShowAllocationDialog(false);
  }

  const onSaveAllocationDialog = async (generalLimit, degreeLimits) => {
    try {
      setApplicationLoading(true);
      const body = {
        academicYearId: selectedTerm,
        yearlyLimit: +generalLimit,
        degreeAllocations: degreeLimits.map(item => ({ degreeId: item.degree, limit: +item.limit }))
      }
      const service = instructorYearAllocation ? updateTFGInstructorAllocation : createTFGInstructorAllocation;
      const result = await service(selected, body);
      if (!result) throw new Error();
      notification('success', 'La asignación del instructor ha sido guardada correctamente');
      setCoord(prev => {
        const updated = { ...prev };
        const index = updated.yearlyAllocations.findIndex(item => item.academicYearId === result.academicYearId);

        if (~index) {
          updated.yearlyAllocations[index] = result;
        } else {
          updated.yearlyAllocations.push(result);
        }

        return updated
      });
      onHideAllocationDialog();
    } catch {
      notification('error', 'Ha habido un error al actualizar la asignación del instructor');
    } finally {
      setApplicationLoading(false)
    }
  }

  return (
    <div className="tfg-config">
      <TFGConfigResultDialog
        visible={showResultDialog}
        results={results}
        onHide={onHideResultsDialog}
      />
      <InstructorAllocationDialog
        selectedCoord={coord}
        selectedTerm={selectedTerm}
        visible={showAllocationDialog}
        onHide={onHideAllocationDialog}
        onSave={onSaveAllocationDialog}
      />
      <div className="upload-csv">
        <p className="disclaimer">
          Cargue un archivo con las cabeceras:&nbsp;
          <i>{CSVKeys.join(', ')}</i>
          <br /><br />
          Puedes utilizar el botón inferior para copiar las cabeceras requeridas al portapapeles
        </p>
        <div className="upload-csv__actions">
          <Tooltip title="Copiar cabeceras al portapapeles" aria-label="copy">
            <IconButton aria-label="copy">
              <Icon className="copy-icon" onClick={onCopyClick}>
                content_copy
              </Icon>
            </IconButton>
          </Tooltip>
          <input
            type="file"
            onChange={onFilesChange}
            multiple={false}
            style={{ display: 'none' }}
            ref={fileUploadRef}
            accept=".csv"
          />
          <Button
            onClick={onUploadClick}
            variant="contained"
            color="primary"
          >
            Subir CSV
          </Button>
        </div>
      </div>
      <div className="update-instructor">
        <h3>Buscar director</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TFGAcademicYearSelector
              selectedTerm={selectedTerm}
              setSelectedTerm={setSelectedTerm}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputAutoComplete
              key="instructor"
              name="instructor"
              value={selected}
              onChange={setSelected}
              label="Selecciona un director"
              options={managers}
              disabled={!managers.length}
            />
          </Grid>
        </Grid>
        { (!!selectedTerm && !!coord) && (
          <div className="actions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAllocationDialog(true)}
            >
              {`${instructorYearAllocation ? 'Modificar' : 'Crear'} asignación`}
            </Button>
          </div>
        ) }
        { !!instructorYearAllocation && (
          <>
            <hr />
            <div className="academic-year-allocation">
              <p className="title">
                {`Límite general de TFGs para el director en el año académico seleccionado: ${instructorYearAllocation.yearlyLimit}`}
              </p>
            </div>
            <hr />
            <div className="instructor-allocation">
              <p className="title">Límites por titulación configurados para el instructor</p>
              { !!instructorYearAllocation?.degreeAllocations?.length ? (
                <div className="allocation__list">
                  { instructorYearAllocation.degreeAllocations.map(degreeAllocation => {
                    // TODO: Add degreeName?
                    const { degreeId, limit } = degreeAllocation;

                    return (
                      <div key={degreeId} className="allocation__item">
                        <p>{`${degreeId} - Máximo ${limit} TFGs`}</p>
                        {/* <span>{degreeName}</span>
                        <span>{limit}</span> */}
                      </div>
                    )
                    }) }
                </div>
              ) : (
              <p>No hay límites configurados</p>
              ) }
            </div>
          </>
        ) }
        { (!!coord && !!selectedTerm && !instructorYearAllocation) && (
          <p className="empty-list">
            No hay límites configurados para este profesor en el año académico seleccionado
          </p>
        ) }
      </div>
    </div>
  )
}

export default TFGConfig